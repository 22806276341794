import {
  ARBITRUM_MAINNET_BLOCK_FINALITY,
  BSC_MAINNET_BLOCK_FINALITY,
  ETH_MAINNET_BLOCK_FINALITY,
  GNOSIS_MAINNET_BLOCK_FINALITY,
  LOCALHOST_BLOCK_FINALITY,
  OPTIMISM_MAINNET_BLOCK_FINALITY,
  POLYGON_MAINNET_BLOCK_FINALITY,
  SEPOLIA_TESTNET_BLOCK_FINALITY,
  BASE_MAINNET_BLOCK_FINALITY,
} from '../constants/evm/configs';
import {
  ARBITRUM_MAINNET_GATEWAY_ADDRESS,
  BASE_MAINNET_GATEWAY_ADDRESS,
  BSC_MAINNET_GATEWAY_ADDRESS,
  ETH_MAINNET_GATEWAY_ADDRESS,
  GNOSIS_MAINNET_GATEWAY_ADDRESS,
  LOCALHOST_GATEWAY_ADDRESS,
  OPTIMISM_MAINNET_GATEWAY_ADDRESS,
  POLYGON_MAINNET_GATEWAY_ADDRESS,
  SEPOLIA_TESTNET_GATEWAY_ADDRESS,
} from '../constants/evm/gateways';
import { InfrastructureEnv, Network, PayoutNetwork } from '../enums/enums';

export const getChainInfoFromNetwork = (network: Network) => {
  if (!isEvm(network)) {
    throw new Error('Only EVM networks are supported');
  }

  switch (network) {
    case Network.ETH_MAINNET:
      return { id: 1, name: 'Ethereum' };
    case Network.POLYGON_MAINNET:
      return { id: 137, name: 'Polygon' };
    case Network.GNOSIS_MAINNET:
      return { id: 100, name: 'Gnosis' };
    case Network.OPTIMISM_MAINNET:
      return { id: 10, name: 'OP Mainnet' };
    case Network.ARBITRUM_MAINNET:
      return { id: 42161, name: 'Arbitrum One' };
    case Network.BSC_MAINNET:
      return { id: 56, name: 'BNB Smart Chain' };
    case Network.BASE_MAINNET:
      return { id: 8453, name: 'Base' };
    case Network.SEPOLIA_TESTNET:
      return { id: 11155111, name: 'Sepolia' };
    default:
      throw new Error('Unsupported network');
  }
};

export const isEvm = (network: Network) =>
  network === Network.ETH_MAINNET ||
  network === Network.POLYGON_MAINNET ||
  network === Network.GNOSIS_MAINNET ||
  network === Network.OPTIMISM_MAINNET ||
  network === Network.ARBITRUM_MAINNET ||
  network === Network.BSC_MAINNET ||
  network === Network.BASE_MAINNET ||
  network === Network.AVALANCHE_MAINNET ||
  network === Network.SEPOLIA_TESTNET;

export const isDfnsSupported = (network: Network) =>
  network === Network.ETH_MAINNET ||
  // network === Network.POLYGON_MAINNET ||
  // network === Network.ARBITRUM_MAINNET ||
  // network === Network.BSC_MAINNET ||
  network === Network.SOL ||
  network === Network.OPTIMISM_MAINNET;

export const isL2 = (network: Network) =>
  network === Network.OPTIMISM_MAINNET ||
  network === Network.ARBITRUM_MAINNET ||
  network === Network.BASE_MAINNET;

export const isTestnet = (network: Network) =>
  network === Network.SEPOLIA_TESTNET;

export const isWalletNeeded = (network: Network) => network !== Network.BITCOIN;

export const getFinalityBlocks = (network: Network) => {
  switch (network) {
    case Network.LOCALHOST:
      return LOCALHOST_BLOCK_FINALITY;
    case Network.SEPOLIA_TESTNET:
      return SEPOLIA_TESTNET_BLOCK_FINALITY;
    case Network.ETH_MAINNET:
      return ETH_MAINNET_BLOCK_FINALITY;
    case Network.POLYGON_MAINNET:
      return POLYGON_MAINNET_BLOCK_FINALITY;
    case Network.GNOSIS_MAINNET:
      return GNOSIS_MAINNET_BLOCK_FINALITY;
    case Network.OPTIMISM_MAINNET:
      return OPTIMISM_MAINNET_BLOCK_FINALITY;
    case Network.ARBITRUM_MAINNET:
      return ARBITRUM_MAINNET_BLOCK_FINALITY;
    case Network.BSC_MAINNET:
      return BSC_MAINNET_BLOCK_FINALITY;
    case Network.BASE_MAINNET:
      return BASE_MAINNET_BLOCK_FINALITY;
    default:
      throw new Error(`Unsupported network: ${network}`);
  }
};

export const getEvmGatewayAddress: (
  network: Network,
  infrastructureEnv: InfrastructureEnv,
) => string = (network: Network, infrastructureEnv: InfrastructureEnv) => {
  switch (network) {
    case Network.LOCALHOST:
      return LOCALHOST_GATEWAY_ADDRESS[infrastructureEnv];

    case Network.ETH_MAINNET:
      return ETH_MAINNET_GATEWAY_ADDRESS[infrastructureEnv];
    case Network.POLYGON_MAINNET:
      return POLYGON_MAINNET_GATEWAY_ADDRESS[infrastructureEnv];
    case Network.GNOSIS_MAINNET:
      return GNOSIS_MAINNET_GATEWAY_ADDRESS[infrastructureEnv];
    case Network.OPTIMISM_MAINNET:
      return OPTIMISM_MAINNET_GATEWAY_ADDRESS[infrastructureEnv];
    case Network.ARBITRUM_MAINNET:
      return ARBITRUM_MAINNET_GATEWAY_ADDRESS[infrastructureEnv];
    case Network.BSC_MAINNET:
      return BSC_MAINNET_GATEWAY_ADDRESS[infrastructureEnv];
    case Network.BASE_MAINNET:
      return BASE_MAINNET_GATEWAY_ADDRESS[infrastructureEnv];

    case Network.SEPOLIA_TESTNET:
      return SEPOLIA_TESTNET_GATEWAY_ADDRESS[infrastructureEnv];
    default:
      throw new Error(`Gateway not found for: ${network}`);
  }
};

export const networkToPayoutNetwork = (network: Network): PayoutNetwork => {
  switch (network) {
    case Network.ETH_MAINNET:
      return PayoutNetwork.ETH;
    case Network.SOL:
      return PayoutNetwork.SOL;
    case Network.BASE_MAINNET:
      return PayoutNetwork.BASE;
    case Network.AVALANCHE_MAINNET:
      return PayoutNetwork.AVALANCHE;
    case Network.OPTIMISM_MAINNET:
      return PayoutNetwork.OPTIMISM;
    case Network.ARBITRUM_MAINNET:
      return PayoutNetwork.ARBITRUM;
    case Network.POLYGON_MAINNET:
      return PayoutNetwork.POLYGON;
    case Network.WIRE:
      return PayoutNetwork.WIRE;
    case Network.ACH:
      return PayoutNetwork.ACH;
    case Network.ACH_PUSH:
      return PayoutNetwork.ACH_PUSH;
    case Network.ACH_PULL:
      return PayoutNetwork.ACH_PULL;
    case Network.ACH_SAME_DAY:
      return PayoutNetwork.ACH_SAME_DAY;
    case Network.SEPA:
      return PayoutNetwork.SEPA;
    case Network.SUI:
      return PayoutNetwork.SUI;

    default:
      throw new Error(`Unsupported network: ${network}`);
  }
};
